<template>
    <v-main class="htest-main">
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>
        <transition name="fade">
            <section v-if="blockLanding" id="htest-main">
            <v-row no-gutters>
                <v-container
                    fill-height
                    style="max-width: 1140px"
                >
                    <v-row
                        class=""
                    >
                        <v-col
                            cols="12"
                            md="6"
                            class="converter-main"
                        >
                            <v-img
                                src="/img/health/Lifetakt_Logo_w.png"
                                max-width=""
                                class="htest-logo"
                            />
                            <h1
                                class="htest-h1"
                            >
                                Selvitä terveydentilasi 7 minuutissa
                            </h1>
                            <div class="htest-subtitle-main">
                                <p>
                                    Ravitsemus, elintavat, tottumukset ja muut olosuhteet vaikuttavat suoraan kehomme eri järjestelmien kuntoon
                                </p>
                                <p>
                                    Testi auttaa arvioimaan terveydentilaa ja tunnistamaan riskitekijöitä
                                </p>
                                <p>
                                    Testin tulosten perusteella sinulle valitaan yksilölliset suositukset
                                </p>
                            </div>
                            <div class="htest-video-div">
                                <div class="htest-video-btn">
                                    <v-btn
                                        v-if="userData.id != 127"
                                        class="align-self-end"
                                        color="#fff"
                                        @click="btnStartTest"
                                    >
                                        <p class="htest-video-btn-txt">Tee testi</p>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-img
                        src="/img/health/iphone-x-test-fin.png"
                        max-width=""
                        class="htest-iphone"
                    />
                </v-container>
            </v-row>
        </section>
        </transition>

        <transition name="fade">
            <section v-if="blockLanding" id="htest-start">
                <v-container>
                    <v-row
                        style="max-width: 1140px"
                        class="mx-auto"
                    >
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <h2 class="htest-h2-steps">Lyhyesti LifeTakt-testistä</h2>
                            <div class="htest-border-left">
                                <div class="htest-blockquote">
                                    <p>
                                        Terveysongelmat eivät ilmaannu yhdessä päivässä.
                                        <strong>Terveydentilan ja sairauden välillä on kolmaskin tila – esisairaus.</strong>
                                        On paljon tehokkaampaa osallistua ennaltaehkäisevään terveyteen (ennaltaehkäisevä lääketiede) ennen kuin ongelmia ilmenee.
                                        Eli terveenä ollessa tai esisairausvaiheessa.
                                    </p>
                                    <p>
                                        Muutos terveestä tilasta sairauteen tapahtuu kuitenkin usein huomaamatta jopa vuosien aikana.
                                        Tutkimalla elimistö tietokonekuvantamisella, ultraäänellä, verikokeella ja virtsakokeella ongelma selviää jälkikäteen,
                                        eli kun elimistö on jo siirtynyt sairauden vaiheeseen.
                                    </p>
                                    <p>
                                        Herää kysymys.
                                        <strong>Kuinka todeta ongelma sen varhaisessa vaiheessa?</strong>
                                        Juuri tätä varten on kehitetty analyyttinen testi.
                                        Varhaisvaroitusjärjestelmänä se näyttää, kuinka elintapasi ja ruokailutottumuksesi vaikuttavat 9 järjestelmään elimistössä.
                                    </p>
                                </div>
                            </div>
                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-expansion-panels popout focusable>
                                <v-expansion-panel class="htest-exp-panel">
                                    <v-expansion-panel-header class="htest-exp-title">Onko testillä tieteellistä pohjaa?</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-7 htest-step-p-exp">
                                            LifeTakt-testi on integraalinen testi, joka antaa tuloksia henkilön taustatietojen perusteella ja osoittaa arvion
                                            9 kehon järjestelmän yleiskunnosta numeerisessa muodossa.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            <strong>TAUSTATITEDOT</strong> — on kyselyn aikana henkilöstä saatua tietoa (ruokailutottumuksista, elintavoista,
                                            tietyistä oireista tai niiden puuttumisesta), jota käytetään sairauden diagnoosin ja ennusteen määrittämiseen sekä
                                            parhaiden hoito- ja ennaltaehkäisymenetelmien valintaan.
                                        </p>
                                        <ul class="htest-ul-steps">
                                            <li>
                                                <v-icon>mdi-check-circle-outline</v-icon>
                                                Taustatiedot ovat varhaisimmat viitteet ihmisten huonosta terveydestä.
                                            </li>
                                            <li>
                                                <v-icon>mdi-check-circle-outline</v-icon>
                                                Ne auttavat ymmärtämään syy-seuraussuhteita terveyden ylläpitomekanismien häiriöissä (sanogeneesi).
                                            </li>
                                            <li>
                                                <v-icon>mdi-check-circle-outline</v-icon>
                                                Kun nämä yhteydet tiedetään, voidaan rakentaa tervehdyttämisohjelma, eikä hoitaa sairautta.
                                            </li>
                                        </ul>
                                        <p class="htest-step-p-exp">
                                            Taustatietoon pohjautuvaa testausta käytetään laajalti klinikoilla Yhdysvalloissa ja Euroopassa.
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel class="htest-exp-panel">
                                    <v-expansion-panel-header class="htest-exp-title">Antaako testi tarkan diagnoosin?</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-7 htest-step-p-exp">
                                            LifeTakt-testi ei ole yleinen taudinmäärittelijä, joka pystyy tekemään tarkan diagnoosin ilman testejä ja erikoistutkimuksia.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Testi analysoi vastauksesi ja näyttää taustatietojen perusteella kehon järjestelmien todennäköisimmän tilan.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Diagnoosi on lausunto itse sairaudesta, joka on JO alkanut.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Testi on tarkoitettu ongelman estämiseksi, sairauksien kehittymisen ehkäisemiseksi, ja sen selvittämiseksi ennakkoon, mitä elimistössäsi saattaa tapahtua, jolloin ennaltaehkäiseviin toimiin voidaan ryhtyä ajoissa.
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel class="htest-exp-panel">
                                    <v-expansion-panel-header class="htest-exp-title">Miksi minun pitäisi tehdä testi, jos voin hyvin?</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-7 htest-step-p-exp">
                                            Ihmisen siirtyminen terveydentilasta sairaustilaan tapahtuu aina esisairaustilan kautta. Tässä tilassa ihminen voi olla muutamasta päivästä vuosikymmeniin.
                                            <strong>WHO:n arvioiden mukaan noin 75 % maailman väestöstä on esisairaustilassa.</strong>.
                                            Samaan he tuntevat vointinsa suhteellisen hyväksi.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Paljon helpompaa ja halvempaa on palata terveeksi esisairaustilasta. On helpompi estää ongelma kuin taistella sen kanssa.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Lääketiede kuitenkin alkaa hoitaa ihmistä vasta sitten, kun voidaan tehdä diagnoosi.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Jos ihminen pyrkii aktiiviseen pitkään ikään, haluaa olla terve ja energinen pidempään, on hänen huolehdittava terveydestään omatoimisesti ja etukäteen.
                                        </p>
                                        <p class="htest-step-p-exp">
                                            Juuri näitä tarkoituksia varten on kehitetty analyyttinen LifeTakt-testi.
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel class="htest-exp-panel">
                                    <v-expansion-panel-header class="htest-exp-title">Kannattaako minun tehdä testi, jos jo muutenkin tiedän kehoni tilan?</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-7 htest-step-p-exp">
                                            Ehdottomasti kannattaa. Tulosten lisäksi saat eurooppalaisten asiantuntijoiden laatimia suosituksia ravinnon ja elintapojen korjaamiseksi.
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </transition>

        <div class="htest-divider" v-if="blockLanding">
            <v-icon>mdi-circle-small</v-icon>
            <v-icon>mdi-circle-small</v-icon>
            <v-icon>mdi-star-outline</v-icon>
            <v-icon>mdi-circle-small</v-icon>
            <v-icon>mdi-circle-small</v-icon>
        </div>

        <transition name="fade">
            <section v-if="blockLanding" id="htest-start">
                <v-container>
                    <v-row
                        style="max-width: 1140px"
                        class="mx-auto"
                    >
                        <v-col
                            cols="12"
                            md="6"
                            style="position: relative"
                            class="htest-cont-two"
                        >
                            <div class="htest-food">
                                <h1
                                    class="htest-h1-food"
                                >
                                    Tee testi ja saat suosituksia ruokavaliosi ja elintapojesi korjaamiseksi
                                </h1>
                                <div class="htest-subtitle-food">
                                    <p>
                                        Opit mitkä terveyden osa-alueet ovat ongelmallisia ja miten niitä korjataan terveemmiksi ja energisemmiksi.
                                    </p>
                                </div>
                                <div class="htest-btn-food-div">
                                    <div class="htest-food-btn">
                                        <v-btn
                                            v-if="userData.id != 127"
                                            class="align-self-end"
                                            color="#fff"
                                            @click="btnStartTest"
                                        >
                                            <p class="htest-food-btn-txt">Tee testi</p>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-img
                                src="/img/health/htest.jpg"
                                max-width=""
                                class="htest-food-img"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </transition>

        <transition name="fade">
            <section v-if="blockLanding" id="htest-feedback">
                <v-container>
                    <h2
                        class="htest-h2-feedback"
                    >
                        Mitä asiantuntijat sanovat LifeTakt-testistä
                    </h2>
                    <v-row
                        style="max-width: 980px"
                        class="mx-auto"
                    >
                        <v-col
                            cols="12"
                        >
                            <div class="htest-feedback">
                                <p>
                                    Juuri tällaisessa tapauksessa yksinkertainen testi auttaa näkemään heikkouksia, joihin ihminen yleensä kiinnittää huomiota vasta, kun oireet ovat jo, kuten sanotaan, "ilmeisiä".
                                </p>
                                <p>
                                    Ennaltaehkäisyn sanotaan olevan paras lääke. Mutta nykymaailmassa ihmiset ovat liian kiireisiä päivittäisissä askareissaan mennäkseen lääkäriin tai tehdäkseen jonkinlaisia tutkimuksia.
                                </p>
                                <p>
                                    Tämä on mielestäni yksi tehokkaimmista pikatesteistä, jonka avulla voidaan estää taudin puhkeaminen ilman ylimääräistä vaivaa tai ainakin antaa ihmiselle mahdollisuus aloittaa tarvittava hoito, kun sairaus on juuri alkanut ilmetä.
                                </p>
                                <div class="htest-fb-who">
                                    <div
                                        class="v-avatar mx-auto v-card--material__avatar elevation-6 grey htest-fb-avatar"
                                        style="height: 64px; min-width: 64px; width: 64px"
                                    >
                                        <div class="v-image v-responsive theme--light">
                                            <img
                                                 src="/img/health/ganna-feedback.jpg"
                                                 alt=""
                                            >
                                            <div class="v-responsive__content" style="width: 250px"></div>
                                        </div>
                                    </div>
                                    <div class="htest-fb-text">
                                        <h3>Zhanna Fedotova</h3>
                                        <p>
                                            Ravitsemusterapeutti, ravitsemuksen ja terveellisten elintapojen konsultti
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <div class="htest-feedback">
                                <p>
                                    Testi näyttää tarkalleen, mitä kehossa tapahtuu. Useiden vuosien ajan sairauksien syiden poistamisen parissa toimineena ravitsemusterapeuttina ja naturopaattina kohtaan säännöllisesti tilanteita, joissa ihmiset käyvät lukuisissa tarpeettomissa ja vähän tietoa antavissa kokeissa kuluttaen samalla paljon rahaa, aikaa, vaivaa ja hermoja, eikä tuloksia käytännössä ole saatu.
                                </p>
                                <p>
                                    Tämän testin avulla voit määrittää 90 %:n todennäköisyydellä, missä kehon järjestelmässä on häiriöitä ja mihin suuntaan tulisi liikkua ilman arvailua siitä mistä aloittaa ja mitä tehdä. Koska juuri tähän kuluu paljon aikaa ja rahaa.
                                </p>
                                <p>
                                    Testi on ihanteellinen ei vain tavalliselle maallikolle, jolle on tärkeää hallita kehonsa tilaa vähän rahaa ja aikaa kuluttamalla. Siitä on hyötyä myös ehkäisevän lääketieteen alalla työskenteleville asiantuntijoille. Testin avulla voit välttää tarpeettomat testit ja tarpeettomat lääkkeet.
                                </p>
                                <div class="htest-fb-who">
                                    <div
                                        class="v-avatar mx-auto v-card--material__avatar elevation-6 grey htest-fb-avatar"
                                        style="height: 64px; min-width: 64px; width: 64px"
                                    >
                                        <div class="v-image v-responsive theme--light">
                                            <img
                                                src="/img/health/aslan-feedback.jpg"
                                                alt=""
                                            >
                                            <div class="v-responsive__content" style="width: 250px"></div>
                                        </div>
                                    </div>
                                    <div class="htest-fb-text">
                                        <h3>Aslan Naptugov</h3>
                                        <p>
                                            Ravitsemusterapeutti ja naturopaatti
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <div class="htest-feedback">
                                <p>
                                    Lääkärinä voin sanoa, että tällä testillä on korkea kliininen arvo.
                                </p>
                                <p>
                                    Tärkeintä on se, että se pystyy näyttämään yksityiskohtaisen kuvan koko kehon tilasta sekä tunnistamaan sairautta edeltävässä vaiheessa olevat elimet ja järjestelmät. Tämä periaatteessa erottaa sen tavallisista kliinisistä tutkimuksista.
                                </p>
                                <p>
                                    Testi on ihanteellinen kehon itsediagnostiikkaan, ja se on kaikkien saatavilla.

                                </p>
                                <div class="htest-fb-who">
                                    <div
                                        class="v-avatar mx-auto v-card--material__avatar elevation-6 grey htest-fb-avatar"
                                        style="height: 64px; min-width: 64px; width: 64px"
                                    >
                                        <div class="v-image v-responsive theme--light">
                                            <img
                                                src="/img/health/murad-feedback.jpg"
                                                alt=""
                                            >
                                            <div class="v-responsive__content" style="width: 250px"></div>
                                        </div>
                                    </div>
                                    <div class="htest-fb-text">
                                        <h3>Murad Musaev</h3>
                                        <p>
                                            Sisätautilääkäri / iho- ja sukupuolitautilääkäri
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <div class="htest-feedback">
                                <p>
                                    Testin jälkeen olin hämmästynyt! Uskomatonta, että näennäisen yksinkertaisten kysymysten perusteella tulos osoitti täsmälleen samaa, minkä kalliit analyysit osoittivat aiemmin!
                                </p>
                                <p>
                                    Kun tein sen toisen kerran, se osoitti keltaisen alueen (riskivyöhykkeen) yhdessä järjestelmässä. Mutta en tuntenut sitä. Jonkin ajan kuluttua tämä järjestelmä antoi virheen. Eli testin avulla voit tietää tarkalleen, missä on pullonkauloja, ja valmistella ennaltaehkäiseviä toimenpiteitä.
                                </p>
                                <div class="htest-fb-who">
                                    <div
                                        class="v-avatar mx-auto v-card--material__avatar elevation-6 grey htest-fb-avatar"
                                        style="height: 64px; min-width: 64px; width: 64px"
                                    >
                                        <div class="v-image v-responsive theme--light">
                                            <img
                                                src="/img/health/tsareva-feedback.jpg"
                                                alt=""
                                            >
                                            <div class="v-responsive__content" style="width: 250px"></div>
                                        </div>
                                    </div>
                                    <div class="htest-fb-text">
                                        <h3>Julia Tsareva</h3>
                                        <p>
                                            Anti-age-asiantuntija
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                        <div class="htest-btn-fb-div mx-auto">
                            <div class="htest-fb-btn">
                                <v-btn
                                    v-if="userData.id != 127"
                                    class="align-self-end"
                                    color="#03311F"
                                    @click="btnStartTest"
                                >
                                    <p class="htest-fb-btn-txt">Tee testi</p>
                                </v-btn>
                            </div>
                        </div>
                    </v-row>
                </v-container>
            </section>
        </transition>

        <transition name="fade">
            <v-footer
                v-if="blockLanding"
                class="justify-center htest-footer"
                height="80"
            >
                <div class="title font-weight-light text-center">
                    &copy; {{ (new Date()).getFullYear() }} — newlvl.net — LR Health & Beauty´n riippumaton kumppani
                </div>
            </v-footer>
        </transition>

        <transition name="fade">
            <section v-if="blockTest" id="hero">
            <v-row no-gutters>
                <v-container
                    id="leedbot"
                    tag="section"
                    class="leedbot test"
                >
                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            xl="6"
                            lg="8"
                            md="8"
                            sm="10"
                            style="padding-top: 5%; padding-bottom: 0"
                            class="main-col"
                        >
                            <v-card
                                class="v-card-profile v-card--material pa-3"
                                style="margin-top: 0; margin-bottom: 0"
                            >
                                <v-row>
                                    <v-col class="">
                                        <div class="chat-list">
                                            <div>
                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz1"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Ennen testin tekemistä vastaa muutamaan kysymykseen, jotta järjestelmä voisi valita sinulle sopivat kysymykset.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOk1"
                                                                    @click="btnOk1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    HYVÄ
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz2"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Sinun nimesi?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpName"
                                                                    label="Kirjoita nimesi"
                                                                    type="text"
                                                                    v-model.trim="clientData.name"
                                                                    :error-messages="nameErrors"
                                                                    @input="$v.clientData.name.$touch()"
                                                                    @blur="$v.clientData.name.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnName"
                                                                    @click="myName"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Nimeni on
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.name }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz3"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Sukupuolesi?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    color="green"
                                                                    :disabled="disBtnM"
                                                                    @click="btnM"
                                                                >
                                                                    M
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    color="green"
                                                                    :disabled="disBtnG"
                                                                    @click="btnG"
                                                                >
                                                                    N
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz4"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Minkä ikäinen olet
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpAge"
                                                                    label="Syötä valmistuneiden vuosien lukumäärä"
                                                                    type="text"
                                                                    v-model.trim="clientData.age"
                                                                    :error-messages="ageErrors"
                                                                    @input="$v.clientData.age.$touch()"
                                                                    @blur="$v.clientData.age.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnAge"
                                                                    @click="myAge"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    OLEN
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.age }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz5"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Ja viimeinen vaihe:
                                                                    </p>
                                                                    <p>
                                                                        Valitse sinulle sopiva viestisovellus, johon testitulokset ja suositukset voidaan lähettää.
                                                                    </p>
                                                                    <div class="mt-7">
                                                                        <v-btn
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="mr-3"
                                                                            color="success"
                                                                            @click="btnWhatsapp"
                                                                        >
                                                                            <v-icon>mdi-whatsapp</v-icon>
                                                                        </v-btn>
<!--                                                                        <v-btn-->
<!--                                                                            fab-->
<!--                                                                            dark-->
<!--                                                                            small-->
<!--                                                                            class="mr-3"-->
<!--                                                                            color="blue"-->
<!--                                                                            @click="btnTelegram"-->
<!--                                                                        >-->
<!--                                                                            <v-icon>mdi-send</v-icon>-->
<!--                                                                        </v-btn>-->
                                                                        <v-btn
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="mr-3"
                                                                            color="deep-purple lighten-2"
                                                                            @click="btnViber"
                                                                        >
                                                                            <v-icon>fab fa-viber</v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="btnWhatsappTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpWhatsapp"
                                                                        label="Whatsapp"
                                                                        prepend-icon="mdi-whatsapp"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_whatsapp"
                                                                        :error-messages="phoneWhatsappErrors"
                                                                        @input="$v.clientData.phone_whatsapp.$touch()"
                                                                        @blur="$v.clientData.phone_whatsapp.$touch()"
                                                                        hint="358123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz5"
                                                                        @click="btnSendBiz"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Lähettää
                                                                    </v-btn>
                                                                </div>
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
                                                            </div>
<!--                                                            <div v-if="btnTelegramTrue">-->
<!--                                                                <div class="chat-input">-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpTelegram"-->
<!--                                                                        label="Имя пользователя в telegram"-->
<!--                                                                        prepend-icon="mdi-send"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.telegram"-->
<!--                                                                        :error-messages="telegramErrors"-->
<!--                                                                        @input="$v.clientData.telegram.$touch()"-->
<!--                                                                        @blur="$v.clientData.telegram.$touch()"-->
<!--                                                                        hint="username"-->
<!--                                                                        persistent-hint-->
<!--                                                                    />-->
<!--                                                                </div>-->
<!--                                                                <div class="chat-controls">-->
<!--                                                                    <v-btn-->
<!--                                                                        class="chat-btn"-->
<!--                                                                        :disabled="disBtnBiz5"-->
<!--                                                                        @click="btnSendBiz"-->
<!--                                                                    >-->
<!--                                                                        <v-icon class="mr-3">mdi-send</v-icon>-->
<!--                                                                        Отправить-->
<!--                                                                    </v-btn>-->
<!--                                                                </div>-->
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
<!--                                                            </div>-->
                                                            <div v-if="btnViberTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpViber"
                                                                        label="Viber"
                                                                        prepend-icon="fab fa-viber"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_viber"
                                                                        :error-messages="phoneViberErrors"
                                                                        @input="$v.clientData.phone_viber.$touch()"
                                                                        @blur="$v.clientData.phone_viber.$touch()"
                                                                        hint="358123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz5"
                                                                        @click="btnSendBiz"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Lähettää
                                                                    </v-btn>
                                                                </div>
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTestStart"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Siispä aloitetaan testi.
                                                                    </p>
                                                                    <p>Vain vastaamalla REHELLISESTI saat TARKAT tulokset</p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTestStart"
                                                                    @click="btnTestStart"
                                                                >
                                                                    OK
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest1"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 1/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Energian puutetta, voimattomuutta
                                                                    </p>
                                                                    <p>
                                                                        Heräät nuutuneena, päivällä uneliaisuutta, apatiaa, kiinnostus elämää kohtaan katoaa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest1Y"
                                                                    @click="btnTest1Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest1N"
                                                                    @click="btnTest1N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest2"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 2/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Sairauksia yli 2 kertaa vuodessa
                                                                    </p>
                                                                    <p>
                                                                        Vilustumista ja muita sairauksia yli 2 kertaa vuodessa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest2Y"
                                                                    @click="btnTest2Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest2N"
                                                                    @click="btnTest2N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest3"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 3/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Pahanhajuinen hengitys tai kehon haju
                                                                    </p>
                                                                    <p>
                                                                        Tuntuu pian suihkun ja suuhygienian jälkeen.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest3Y"
                                                                    @click="btnTest3Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest3N"
                                                                    @click="btnTest3N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest4"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 4/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Tietyt ruoat sulavat huonosti (raskas olo)
                                                                    </p>
                                                                    <p>
                                                                        Jatkuvaa tai ajoittaista vatsakipua kahden tunnin ajan ruokailun jälkeen.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest4Y"
                                                                    @click="btnTest4Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest4N"
                                                                    @click="btnTest4N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest5"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 5/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Punaisen lihan syöminen
                                                                    </p>
                                                                    <p>
                                                                        Punaisen lihan syöminen (nauta, lammas, verinen pihvi) 2 kertaa viikossa tai useammin.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest5Y"
                                                                    @click="btnTest5Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest5N"
                                                                    @click="btnTest5N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest6"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 6/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Kuukautiskiertoon liittyviä ongelmia, mm. kivuliaat kuukautiset
                                                                    </p>
                                                                    <p>
                                                                        Kuukautiskierto alle 21 päivää tai yli 32 päivää. Kuukautisiin liittyy kipua.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest6Y"
                                                                    @click="btnTest6Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest6N"
                                                                    @click="btnTest6N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest7"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 7/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Antibioottien (lääkkeiden) käyttö
                                                                    </p>
                                                                    <p>
                                                                        Antibioottien ja muiden lääkkeiden käyttöä yli 2 kertaa vuodessa viimeisen 3 vuoden aikana.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest7Y"
                                                                    @click="btnTest7Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest7N"
                                                                    @click="btnTest7N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest8"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 8/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Alkoholin nauttiminen (myös oluen)
                                                                    </p>
                                                                    <p>
                                                                        Alkoholin (myös oluen) nauttiminen useammin kuin kerran viikossa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest8Y"
                                                                    @click="btnTest8Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest8N"
                                                                    @click="btnTest8N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest9"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 9/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Toistuvat mielialan vaihtelut
                                                                    </p>
                                                                    <p>
                                                                        Et pysty ylläpitämään tunnetaustaasi tasaisena koko päivän aikana.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest9Y"
                                                                    @click="btnTest9Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest9N"
                                                                    @click="btnTest9N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest10"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 10/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Allergia
                                                                    </p>
                                                                    <p>
                                                                        Ihottuma, kutina, turvotus, nuhaisuus.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest10Y"
                                                                    @click="btnTest10Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest10N"
                                                                    @click="btnTest10N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest11"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 11/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Tummat silmäympärykset tai turvotusta silmien alla
                                                                    </p>
                                                                    <p>
                                                                        Silmäpussit tai tummat silmänaluset.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest11Y"
                                                                    @click="btnTest11Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest11N"
                                                                    @click="btnTest11N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest12"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 12/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Tupakointi (myös passiivinen)
                                                                    </p>
                                                                    <p>
                                                                        Tupakoit säännöllisesti tai alkoholin nauttimisen jälkeen ystäväpiirissä, myös toistuva läsnäolo tupakoitsijoiden keskuudessa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest12Y"
                                                                    @click="btnTest12Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest12N"
                                                                    @click="btnTest12N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest13"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 13/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Keskittymisvaikeudet, huono muisti
                                                                    </p>
                                                                    <p>
                                                                        Hajamielisyys, lyhytaikainen muisti kärsii.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest13Y"
                                                                    @click="btnTest13Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest13N"
                                                                    @click="btnTest13N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest14"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 14/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Epämukava tunne ruokailun jälkeen (närästys, kaasut)
                                                                    </p>
                                                                    <p>
                                                                        Vähintään kerran päivässä ja varsinkin tietyntyyppisen ruoan jälkeen.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest14Y"
                                                                    @click="btnTest14Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest14N"
                                                                    @click="btnTest14N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest15"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 15/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Rauhaton ympäristö, toistuva stressi
                                                                    </p>
                                                                    <p>
                                                                        Selkeästi stressaavien tilanteiden lisäksi esiintyy syyllisyyden tunnetta, kaunaa, huolta, ahdistusta.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest15Y"
                                                                    @click="btnTest15Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest15N"
                                                                    @click="btnTest15N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest16"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 16/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Ihovaurioita tai epätyydyttävä kasvojen ihonväri
                                                                    </p>
                                                                    <p>
                                                                        Ihottuma, akne, ikäläiskät, papilloomat kasvojen iholla.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest16Y"
                                                                    @click="btnTest16Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest16N"
                                                                    @click="btnTest16N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest17"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 17/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Makeisten tai puolivalmisteiden käyttö (myös pikaruoka)
                                                                    </p>
                                                                    <p>
                                                                        Jälkiruokien säännöllinen nauttiminen aterioiden aikana, makeisten naposteleminen. Syöt enemmän prosessoitua ruokaa kuin raakaa ruokaa. Syöt pikaruokaa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest17Y"
                                                                    @click="btnTest17Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest17N"
                                                                    @click="btnTest17N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest18"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 18/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Maitotuotteiden käyttöä yli 2 kertaa viikossa
                                                                    </p>
                                                                    <p>
                                                                        Maitotuotteiden ja niiden johdannaisten (raejuusto, voi, juustot jne.) käyttöä yli 2 kertaa viikossa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest18Y"
                                                                    @click="btnTest18Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest18N"
                                                                    @click="btnTest18N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest19"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 19/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Apatian tunnetta, velttoutta, masennusta
                                                                    </p>
                                                                    <p>
                                                                        Pitkään aikaan et pääse irti sellaisista tunteista.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest19Y"
                                                                    @click="btnTest19Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest19N"
                                                                    @click="btnTest19N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest20"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 20/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Levoton uni, unettomuus
                                                                    </p>
                                                                    <p>
                                                                        Et nukahda ensimmäisten 10 minuutin aikana tai heräät usein pieneenkin rapinaan.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest20Y"
                                                                    @click="btnTest20Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest20N"
                                                                    @click="btnTest20N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest21"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 21/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Vaihdevuodet, kuumat aallot
                                                                    </p>
                                                                    <p>
                                                                        Korkea verenpaine, kuumat aallot, hikoilu jne.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest21Y"
                                                                    @click="btnTest21Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest21N"
                                                                    @click="btnTest21N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest22"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 22/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Virtsaamisvaivat tai virtsarakon sairaudet
                                                                    </p>
                                                                    <p>
                                                                        Virtsaamista yli 7 kertaa päivässä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest22Y"
                                                                    @click="btnTest22Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest22N"
                                                                    @click="btnTest22N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest23"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 23/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Herkkä (ohentunut) iho
                                                                    </p>
                                                                    <p>
                                                                        Kiristävä tunne pesun jälkeen, toistuva ärsytys, punaiset täplät, polttava tunne, kutina, hilseily, reagointi uuden kosmetiikkatuotteen käyttöön.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest23Y"
                                                                    @click="btnTest23Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest23N"
                                                                    @click="btnTest23N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest24"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 24/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Hiustenlähtö tai päänahan ongelmat
                                                                    </p>
                                                                    <p>
                                                                        Havaitset, että pesussa irtoaa normaalia enemmän hiuksia, myös kammatessa ja nukkumisen jälkeen tyynyllä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest24Y"
                                                                    @click="btnTest24Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest24N"
                                                                    @click="btnTest24N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest25"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 25/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Kipua nivelissä, rutinaa, turvotusta. Raajojen puutumista.
                                                                    </p>
                                                                    <p>
                                                                        Havaitset turvotusta ja kipua nivelissä, etenkin pitkän istumisen jälkeen.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest25Y"
                                                                    @click="btnTest25Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest25N"
                                                                    @click="btnTest25N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest26"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 26/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Poikkeama normaalipainosta
                                                                    </p>
                                                                    <p>
                                                                        Paino putoaa vain ruokavalion rajoituksin ja dieetin aikana. Tai paino ei nouse edes tehostetulla ravinnolla.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest26Y"
                                                                    @click="btnTest26Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest26N"
                                                                    @click="btnTest26N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest27"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 27/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Nopea väsymys
                                                                    </p>
                                                                    <p>
                                                                        Voimattomuuden ja kestävyyden puute, jatkuvaa levon tarve.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest27Y"
                                                                    @click="btnTest27Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest27N"
                                                                    @click="btnTest27N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest28"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 28/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Ruokailutottumuksen häiriöt
                                                                    </p>
                                                                    <p>
                                                                        Epäsäännöllinen, riittämätön ruokailu harvemmin kuin 3 kertaa päivässä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest28Y"
                                                                    @click="btnTest28Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest28N"
                                                                    @click="btnTest28N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest29"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 29/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Pitkä toipuminen sairauden jälkeen
                                                                    </p>
                                                                    <p>
                                                                        Vilustumisesta toipuminen kestää yli 1 viikon.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest29Y"
                                                                    @click="btnTest29Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest29N"
                                                                    @click="btnTest29N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest30"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 30/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Epäsäännöllinen uloste
                                                                    </p>
                                                                    <p>
                                                                        Kun päivässä on 3 ateriaa, niin suoli tyhjenee alle 2 kertaa päivässä tai ilmenee selvää ummetusta.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest30Y"
                                                                    @click="btnTest30Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest30N"
                                                                    @click="btnTest30N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest31"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 31/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Huono ruokahalu
                                                                    </p>
                                                                    <p>
                                                                        On pakotettava itsensä syömään jotain.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest31Y"
                                                                    @click="btnTest31Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest31N"
                                                                    @click="btnTest31N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest32"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 32/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Ohuet ja hauraat kynnet (lohkeilevat kynnet)
                                                                    </p>
                                                                    <p>
                                                                        Vaikea kasvattaa kynsiä, kun ne katkeavat pienestäkin mekaanisesta vaikutuksesta.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest32Y"
                                                                    @click="btnTest32Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest32N"
                                                                    @click="btnTest32N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest33"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 33/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Vaurioituneet hiukset (kuivat tai kiillottomat)
                                                                    </p>
                                                                    <p>
                                                                        Hiusten hauraus juurivyöhykkeellä tai 5-10 cm hiusjuuresta, haaroittuneet latvat.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest33Y"
                                                                    @click="btnTest33Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest33N"
                                                                    @click="btnTest33N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest34"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 34/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Rasvaisten ruokien nauttiminen
                                                                    </p>
                                                                    <p>
                                                                        Paistettujen, öljyisten ja runsaasti rasvaa sisältävien ruokien nauttiminen yli 2 kertaa viikossa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest34Y"
                                                                    @click="btnTest34Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest34N"
                                                                    @click="btnTest34N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest35"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 35/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Kuitujen puute ruokavaliossa
                                                                    </p>
                                                                    <p>
                                                                        Vihannesten käyttö, mm. salaatit - alle 2 kertaa päivässä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest35Y"
                                                                    @click="btnTest35Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest35N"
                                                                    @click="btnTest35N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest36"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 36/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Lihasvaivat (kipu, kouristukset)
                                                                    </p>
                                                                    <p>
                                                                        Usein kouristuksia aamulla, vedessä, kävellessä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest36Y"
                                                                    @click="btnTest36Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest36N"
                                                                    @click="btnTest36N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest37"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 37/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Epäsuotuisa ympäristö
                                                                    </p>
                                                                    <p>
                                                                        Asut suurkaupungissa, lähellä on tehtaita tai myrkyllistä tuotantoa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest37Y"
                                                                    @click="btnTest37Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest37N"
                                                                    @click="btnTest37N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest38"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 38/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Uneliaisuutta päivällä
                                                                    </p>
                                                                    <p>
                                                                        Haluan aina nukkua päivällä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest38Y"
                                                                    @click="btnTest38Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest38N"
                                                                    @click="btnTest38N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest39"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 39/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Colan, kahvin tai vahvan teen päivittäinen nauttiminen
                                                                    </p>
                                                                    <p>
                                                                        Juot enemmän kuin 2 kupillista päivässä colaa, kahvia tai vahvaa teetä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest39Y"
                                                                    @click="btnTest39Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest39N"
                                                                    @click="btnTest39N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest40"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 40/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Herkkyys kemikaaleille, lääkkeille, tietyille elintarvikkeille
                                                                    </p>
                                                                    <p>
                                                                        Kaikenlaiset vaivat: kutina, hengenahdistus, turvotus.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest40Y"
                                                                    @click="btnTest40Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest40N"
                                                                    @click="btnTest40N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest41"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 41/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Sienivaivat
                                                                    </p>
                                                                    <p>
                                                                        Valkoisen katteen esiintyminen kielessä aamulla, vuoto naisilla, kynsisieni.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest41Y"
                                                                    @click="btnTest41Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest41N"
                                                                    @click="btnTest41N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest42"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 42/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Lihasten heikkous tai hauraat luut
                                                                    </p>
                                                                    <p>
                                                                        Usein murtumia, ajoittain vaappuva kävelytapa.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest42Y"
                                                                    @click="btnTest42Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest42N"
                                                                    @click="btnTest42N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest43"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 43/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Murehtimisen tunne
                                                                    </p>
                                                                    <p>
                                                                        Jatkuvaa huolestumista ja usein aiheetta
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest43Y"
                                                                    @click="btnTest43Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest43N"
                                                                    @click="btnTest43N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest44"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 44/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Lisääntynyt ärtyneisyys, liiallinen kiihtyneisyys
                                                                    </p>
                                                                    <p>
                                                                        Tulet ärtyisäksi ja vihaiseksi, ja ymmärrät, ettei tähän ole hyviä syitä.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest44Y"
                                                                    @click="btnTest44Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest44N"
                                                                    @click="btnTest44N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest45"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 45/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Vähäliikkeinen elintapa, alhainen fyysinen aktiivisuus
                                                                    </p>
                                                                    <p>
                                                                        Istuva elintapa, vähäliikkeinen työ, ei mitään fyysistä aktiivisuutta.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest45Y"
                                                                    @click="btnTest45Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest45N"
                                                                    @click="btnTest45N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest46"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 46/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Lisääntynyt limaneritys (räkää)
                                                                    </p>
                                                                    <p>
                                                                        Limaista yskää aamulla ja päivän kuluessa, riippumatta vilustumisesta, lisääntynyt syljeneritys.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest46Y"
                                                                    @click="btnTest46Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest46N"
                                                                    @click="btnTest46N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest47"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="p-quest-num mt-5 mb-5">Kysymys: 47/47</p>
                                                                    <p class="mb-5" style="font-weight: bold; font-size: 22px">
                                                                        Suuret ihohuokoset, lisääntynyt hikoilu (akne)
                                                                    </p>
                                                                    <p>
                                                                        Rasvainen iho, suuret huokoset, akne, muut ihottumat.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls quest">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest47Y"
                                                                    @click="btnTest47Y"
                                                                >
                                                                    PIKEMMINKIN KYLLÄ
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest47N"
                                                                    @click="btnTest47N"
                                                                >
                                                                    PIKEMMINKIN EI
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="mesTestRes"
                                                    >
                                                        <p class="mb-5"
                                                           style="font-weight: bold"
                                                        >
                                                            Testitulokset
                                                        </p>
                                                        <v-simple-table>
                                                            <template v-slot:default>
                                                                <thead>
                                                                <tr>
                                                                    <th class="text-center dialog-th">
                                                                        Järjestelmä
                                                                    </th>
                                                                    <th class="text-center dialog-th" style="width: 120px">
                                                                        Oikein hyvä
                                                                    </th>
                                                                    <th class="text-center dialog-th" style="width: 120px">
                                                                        Hyvä
                                                                    </th>
                                                                    <th class="text-center dialog-th" style="width: 120px">
                                                                        Riskialue
                                                                    </th>
                                                                    <th class="text-center dialog-th" style="width: 120px">
                                                                        Huono
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <span
                                                                                    class="sys-icon"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                >
                                                                                    <img src="/img/testsysicons/gkt-icon.png" alt="">
                                                                                </span>
                                                                            </template>
                                                                            <span>Ruoansulatuselimistö</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Ruoansulatuskanavan</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-2</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.gktSys < 3"
                                                                        >
                                                                                {{ testResults.gktSys }}
                                                                            </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">3-4</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.gktSys > 2 && testResults.gktSys < 5"
                                                                        >
                                                                                {{ testResults.gktSys }}
                                                                            </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">5-9</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.gktSys > 4 && testResults.gktSys < 10"
                                                                        >
                                                                                {{ testResults.gktSys }}
                                                                            </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">10></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.gktSys > 9"
                                                                        >
                                                                                {{ testResults.gktSys }}
                                                                            </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/heart-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Kardiovaskulaarinen</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Kardiovaskulaarinen</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-2</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.heartSys < 3"
                                                                        >
                                                                            {{ testResults.heartSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">3</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.heartSys === 3"
                                                                        >
                                                                            {{ testResults.heartSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">4-7</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.heartSys > 3 && testResults.heartSys < 8"
                                                                        >
                                                                            {{ testResults.heartSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">8></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.heartSys > 7"
                                                                        >
                                                                            {{ testResults.heartSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/nerv-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Hermosto</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Hermosto</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-2</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.nervSys < 3"
                                                                        >
                                                                            {{ testResults.nervSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">3-5</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.nervSys > 2 && testResults.nervSys < 6"
                                                                        >
                                                                            {{ testResults.nervSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">6-9</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.nervSys > 5 && testResults.nervSys < 10"
                                                                        >
                                                                            {{ testResults.nervSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">10></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.nervSys > 9"
                                                                        >
                                                                            {{ testResults.nervSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/imun-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Immuunijärjestelmä</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Immuunijärjestelmä</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-2</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.imunSys < 3"
                                                                        >
                                                                            {{ testResults.imunSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">3-4</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.imunSys > 2 && testResults.imunSys < 5"
                                                                        >
                                                                            {{ testResults.imunSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">5-7</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.imunSys > 4 && testResults.imunSys < 8"
                                                                        >
                                                                            {{ testResults.imunSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">8></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.imunSys > 7"
                                                                        >
                                                                            {{ testResults.imunSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/breath-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Hengitystiet</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Hengitystiet</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.breathSys === 0"
                                                                        >
                                                                            {{ testResults.breathSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">1-3</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.breathSys > 0 && testResults.breathSys < 4"
                                                                        >
                                                                            {{ testResults.breathSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">4-5</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.breathSys > 3 && testResults.breathSys < 6"
                                                                        >
                                                                            {{ testResults.breathSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">6></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.breathSys > 5"
                                                                        >
                                                                            {{ testResults.breathSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/mocha-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Virtsaneritys</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Virtsaneritys</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.mochaSys === 0"
                                                                        >
                                                                            {{ testResults.mochaSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">1</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.mochaSys === 1"
                                                                        >
                                                                            {{ testResults.mochaSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">2-4</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.mochaSys > 1 && testResults.mochaSys < 5"
                                                                        >
                                                                            {{ testResults.mochaSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">5></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.mochaSys > 4"
                                                                        >
                                                                            {{ testResults.mochaSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/endo-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Endokriininen</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Endokriininen</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-2</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.endoSys < 3"
                                                                        >
                                                                            {{ testResults.endoSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">3-5</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.endoSys > 2 && testResults.endoSys < 6"
                                                                        >
                                                                            {{ testResults.endoSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">6-9</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.endoSys > 5 && testResults.endoSys < 10"
                                                                        >
                                                                            {{ testResults.endoSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">10></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.endoSys > 9"
                                                                        >
                                                                            {{ testResults.endoSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/move-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Tuki-ja liikuntaelimistö</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Tuki-ja liikuntaelimistö</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-1</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.opdvigSys < 2"
                                                                        >
                                                                            {{ testResults.opdvigSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">2-3</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.opdvigSys > 1 && testResults.opdvigSys < 4"
                                                                        >
                                                                            {{ testResults.opdvigSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">4-8</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.opdvigSys > 3 && testResults.opdvigSys < 9"
                                                                        >
                                                                            {{ testResults.opdvigSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">9></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.opdvigSys > 8"
                                                                        >
                                                                            {{ testResults.opdvigSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr class="tbl-tr">
                                                                    <td class="tbl-sys">
                                                                        <v-tooltip right>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                    <span
                                                                                        class="sys-icon"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                        <img src="/img/testsysicons/skin-icon.png" alt="">
                                                                                    </span>
                                                                            </template>
                                                                            <span>Iho</span>
                                                                        </v-tooltip>
                                                                        <p class="sys-text">Iho</p>
                                                                    </td>
                                                                    <td class="tbl-very-good">
                                                                        <span class="table-label">0-1</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.skinSys < 2"
                                                                        >
                                                                            {{ testResults.skinSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-good">
                                                                        <span class="table-label">2-3</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.skinSys > 1 && testResults.skinSys < 4"
                                                                        >
                                                                            {{ testResults.skinSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-risk">
                                                                        <span class="table-label">4-6</span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.skinSys > 3 && testResults.skinSys < 7"
                                                                        >
                                                                            {{ testResults.skinSys }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="tbl-bad">
                                                                        <span class="table-label">7></span>
                                                                        <span class="table-result"
                                                                              v-if="testResults.skinSys > 6"
                                                                        >
                                                                            {{ testResults.skinSys }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div

                                                        v-if="mesTestZone"
                                                    >
                                                        <v-simple-table class="mt-10 mb-10">
                                                            <template v-slot:default>
                                                                <tbody>
                                                                <tr>
                                                                    <td style="width: 450px; background-color: #bcfea4; padding-top: 10px; padding-bottom: 10px">
                                                                        Erittäin hyvin toimivat järjestelmät:
                                                                        <ul>
                                                                            <li class="span-res-sys" v-if="testResults.gktSys < 3">
                                                                                ruoansulatus
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.heartSys < 3">
                                                                                kardiovaskulaarinen
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.nervSys < 3">
                                                                                hermosto
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.imunSys < 3">
                                                                                immuunijärjestelmä
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.breathSys < 1">
                                                                                hengitystiet
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.mochaSys < 1">
                                                                                virtsaneritys
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.endoSys < 3">
                                                                                endokriininen
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.opdvigSys < 2">
                                                                                tuki-ja liikuntaelimistö
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.skinSys < 2">
                                                                                iho
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 450px; background-color: #b7e0fe; padding-top: 10px; padding-bottom: 10px">
                                                                        Järjestelmät, jotka toimivat hyvin, mutta ovat jo alkaneet siirtyä RISKIALUEELLE:
                                                                        <ul>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.gktSys > 2 && testResults.gktSys < 5">
                                                                                ruoansulatus
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.heartSys === 3">
                                                                                kardiovaskulaarinen
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.nervSys > 2 && testResults.nervSys < 6">
                                                                                hermosto
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.imunSys > 2 && testResults.imunSys < 5">
                                                                                immuunijärjestelmä
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.breathSys > 0 && testResults.breathSys < 4">
                                                                                hengitystiet
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.mochaSys === 1">
                                                                                virtsaneritys
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.endoSys > 2 && testResults.endoSys < 6">
                                                                                endokriininen
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.opdvigSys > 1 && testResults.opdvigSys < 4">
                                                                                tuki-ja liikuntaelimistö
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.skinSys > 1 && testResults.skinSys < 4">
                                                                                iho
                                                                            </li>
                                                                        </ul>
                                                                        Jos mitään ei muuteta ravitsemuksessa ja elämäntavoissa, nämä järjestelmät siirtyvät ajan myötä RISKIALUEELLE.
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 450px; background-color: #feffb3; padding-top: 10px; padding-bottom: 10px">
                                                                        Järjestelmät, jotka ovat vaarallisimmalla RISKIALUEELLA:
                                                                        <ul>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.gktSys > 4 && testResults.gktSys < 10">
                                                                                ruoansulatus
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.heartSys > 3 && testResults.heartSys < 8">
                                                                                kardiovaskulaarinen
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.nervSys > 5 && testResults.nervSys < 10">
                                                                                hermosto
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.imunSys > 4 && testResults.imunSys < 8">
                                                                                immuunijärjestelmä
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.breathSys > 3 && testResults.breathSys < 6">
                                                                                hengitystiet
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.mochaSys > 1 && testResults.mochaSys < 5">
                                                                                virtsaneritys
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.endoSys > 5 && testResults.endoSys < 10">
                                                                                endokriininen
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.opdvigSys > 3 && testResults.opdvigSys < 9">
                                                                                tuki-ja liikuntaelimistö
                                                                            </li>
                                                                            <li class="span-res-sys"
                                                                                v-if="testResults.skinSys > 3 && testResults.skinSys < 7">
                                                                                iho
                                                                            </li>
                                                                        </ul>
                                                                        Stressi voi milloin tahansa aiheuttaa toimintahäiriön missä tahansa elimistön järjestelmässä, mikä voi johtaa vakaviin sairauksiin!
                                                                        <strong style="text-decoration: underline">Et voi viivytellä! On kiireesti pidettävä huolta terveydestä!</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 450px; background-color: #ffc5c4; padding-top: 10px; padding-bottom: 10px">
                                                                        Järjestelmät ovat vaurioituneet tai sairauksia saattaa ilmaantua lähitulevaisuudessa:
                                                                        <ul>
                                                                            <li class="span-res-sys" v-if="testResults.gktSys > 9">
                                                                                ruoansulatus
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.heartSys > 7">
                                                                                kardiovaskulaarinen
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.nervSys > 9">
                                                                                hermosto
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.imunSys > 7">
                                                                                immuunijärjestelmä
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.breathSys > 5">
                                                                                hengitystiet
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.mochaSys > 4">
                                                                                virtsaneritys
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.endoSys > 9">
                                                                                endokriininen
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.opdvigSys > 8">
                                                                                tuki-ja liikuntaelimistö
                                                                            </li>
                                                                            <li class="span-res-sys" v-if="testResults.skinSys > 6">
                                                                                iho
                                                                            </li>
                                                                        </ul>
                                                                            Ota kiireesti yhteyttä asiantuntijaan, jos et ole vielä tehnyt niin!
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTestReq"
                                                    >
                                                        <div class="chat-block">
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Lähiaikoina henkilökohtainen LR-konsulttisi lähettää sinulle suosituksia ravitsemuksesta ja terveyden edistämisestä valitsemaasi viestipalveluun
                                                                    </p>
                                                                    <p class="mb-3 mr-3">
                                                                        Tai voit itse ottaa yhteyttä henkilökohtaiseen LR-konsulttiisi:
                                                                    </p>
                                                                </div>
                                                                <v-card
                                                                    class="v-card-profile v-card--material mt-16 pa-3 mx-auto"
                                                                    max-width="430"
                                                                >
                                                                    <div class="d-flex grow flex-wrap">
                                                                        <div
                                                                            class="v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                                                                            style="height: 128px; min-width: 128px; width: 128px"
                                                                        >
                                                                            <div class="v-image v-responsive theme--light">
                                                                                <img v-if="userData.avatar"
                                                                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                                     alt=""
                                                                                >
                                                                                <div class="v-responsive__content" style="width: 250px"></div>
                                                                            </div>
                                                                        </div>

                                                                        <v-card-text class="text-center">
                                                                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                                                                {{ userData.name }} {{ userData.last_name }}
                                                                            </h4>

                                                                            <h6
                                                                                class="display-1 mb-3 green--text"
                                                                                v-if="userData.who_is"
                                                                            >
                                                                                <v-icon class="mr-2 green--text">mdi-seal-variant</v-icon>
                                                                                {{ userData.who_is }}
                                                                            </h6>

                                                                            <p v-if="userData.country || userData.city">
                                                                                <span v-if="userData.country">{{ userData.country }}</span>
                                                                                <span v-if="userData.country && userData.city">, </span>
                                                                                <span v-if="userData.city">{{ userData.city }}</span>
                                                                            </p>
                                                                        </v-card-text>
                                                                    </div>
                                                                    <v-divider style="padding-bottom: 20px"/>
                                                                    <v-row>
                                                                        <v-col class="text-center">
                                                                            <div class="input-group mb-3">
                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.phone_whatsapp"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            style="text-decoration: none"
                                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                                            target="_blank"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="success"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>WhatsApp</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.telegram"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            style="text-decoration: none"
                                                                                            :href="`${userData.telegram}`"
                                                                                            target="_blank"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="blue"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>mdi-send</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>Telegram</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.phone_viber"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                                            target="_blank"
                                                                                            style="text-decoration: none"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="deep-purple lighten-2"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>fab fa-viber</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>Viber</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.instagram"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            :href="`${userData.instagram}`"
                                                                                            target="_blank"
                                                                                            style="text-decoration: none"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="pink darken-1"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>mdi-instagram</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>Instagram</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.fb_messenger"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            :href="`${userData.fb_messenger}`"
                                                                                            target="_blank"
                                                                                            style="text-decoration: none"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="light-blue"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>Facebook Messenger</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.vkontakte"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            :href="`${userData.vkontakte}`"
                                                                                            target="_blank"
                                                                                            style="text-decoration: none"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="blue darken-2"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>fab fa-vk</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>VKontakte</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip
                                                                                    top
                                                                                    v-if="userData.odnoklassniki"
                                                                                >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <a
                                                                                            :href="`${userData.odnoklassniki}`"
                                                                                            target="_blank"
                                                                                            style="text-decoration: none"
                                                                                        >
                                                                                            <v-btn
                                                                                                fab
                                                                                                dark
                                                                                                small
                                                                                                class="mr-4"
                                                                                                color="orange darken-2"
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                                            </v-btn>
                                                                                        </a>
                                                                                    </template>
                                                                                    <span>Odnoklassniki</span>
                                                                                </v-tooltip>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
<!--                                            <div-->
<!--                                                class="chat-typing"-->
<!--                                                v-bind:class="{show: isShow}"-->
<!--                                            >-->
<!--                                                {{ userData.name }} печатает-->
<!--                                                <div class="chat-typing-animate">-->
<!--                                                    <div class="chat-typing-box">-->
<!--                                                        ...-->
<!--                                                        <img src="/img/leedbot/edit.svg" alt="">-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                        </div>
<!--                                        <div id="scrollToMe"></div>-->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
        </transition>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {validationMixin} from 'vuelidate'
import {required, minLength, maxLength, numeric, helpers} from 'vuelidate/lib/validators'
import * as authService from "@/services/auth_service"

const alpha = helpers.regex('alpha', /^[a-zA-Zа-яёА-ЯЁ]*$/)

export default {
    name: "HealthTest",
    mixins: [validationMixin],
    data: () => ({
        isShow: false,
        loading: true,
        lr_number: '',
        inst: '',
        errors: {},
        // nowDateTime: {
        //     dateTime1: '',
        //     dateTime2: '',
        //     dateTime3: '',
        //     dateTime4: '',
        //     dateTime5: '',
        //     dateTime6: '',
        //     dateTime7: '',
        //     dateTime8: ''
        // },
        clientData: {
            name: '',
            gender: '',
            age: '',
            phone: '',
            partner: '',
            instrument: 'Тест по здоровью',
            step: 'Новый',
        },
        prospectData: {
            id: '',
            test_results: ''
        },
        testResults: {
            gktSys: 0,
            heartSys: 0,
            nervSys: 0,
            imunSys: 0,
            breathSys: 0,
            mochaSys: 0,
            endoSys: 0,
            opdvigSys: 0,
            skinSys: 0,
        },
        cookieShow: true,
        blockLanding: true,
        blockTest: false,
        disBtnStartTest: false,
        // mesFirst: false,
        // mesSecond: false,
        // disBtnBiz: false,
        // answBizProd: false,
        mesBiz1: true,
        disInpName: false,
        disInpPhone: false,
        disBtnName: false,
        // answName: false,
        // answOk1: false,
        disBtnOk1: false,
        mesBiz2: false,
        mesBiz3: false,
        disBtnM: false,
        disBtnG: false,
        // answGender: false,
        mesBiz4: false,
        disInpAge: false,
        disBtnAge: false,
        // answAge: false,
        mesBiz5: false,
        disBtnBiz5: false,
        btnWhatsappTrue: false,
        // btnTelegramTrue: false,
        btnViberTrue: false,
        disInpWhatsapp: false,
        // disInpTelegram: false,
        disInpViber: false,
        mesTestStart: false,
        disBtnTestStart: false,
        mesTest1: false,
        disBtnTest1Y: false,
        disBtnTest1N: false,
        mesTest2: false,
        disBtnTest2Y: false,
        disBtnTest2N: false,
        mesTest3: false,
        disBtnTest3Y: false,
        disBtnTest3N: false,
        mesTest4: false,
        disBtnTest4Y: false,
        disBtnTest4N: false,
        mesTest5: false,
        disBtnTest5Y: false,
        disBtnTest5N: false,
        mesTest6: false,
        disBtnTest6Y: false,
        disBtnTest6N: false,
        mesTest7: false,
        disBtnTest7Y: false,
        disBtnTest7N: false,
        mesTest8: false,
        disBtnTest8Y: false,
        disBtnTest8N: false,
        mesTest9: false,
        disBtnTest9Y: false,
        disBtnTest9N: false,
        mesTest10: false,
        disBtnTest10Y: false,
        disBtnTest10N: false,
        mesTest11: false,
        disBtnTest11Y: false,
        disBtnTest11N: false,
        mesTest12: false,
        disBtnTest12Y: false,
        disBtnTest12N: false,
        mesTest13: false,
        disBtnTest13Y: false,
        disBtnTest13N: false,
        mesTest14: false,
        disBtnTest14Y: false,
        disBtnTest14N: false,
        mesTest15: false,
        disBtnTest15Y: false,
        disBtnTest15N: false,
        mesTest16: false,
        disBtnTest16Y: false,
        disBtnTest16N: false,
        mesTest17: false,
        disBtnTest17Y: false,
        disBtnTest17N: false,
        mesTest18: false,
        disBtnTest18Y: false,
        disBtnTest18N: false,
        mesTest19: false,
        disBtnTest19Y: false,
        disBtnTest19N: false,
        mesTest20: false,
        disBtnTest20Y: false,
        disBtnTest20N: false,
        mesTest21: false,
        disBtnTest21Y: false,
        disBtnTest21N: false,
        mesTest22: false,
        disBtnTest22Y: false,
        disBtnTest22N: false,
        mesTest23: false,
        disBtnTest23Y: false,
        disBtnTest23N: false,
        mesTest24: false,
        disBtnTest24Y: false,
        disBtnTest24N: false,
        mesTest25: false,
        disBtnTest25Y: false,
        disBtnTest25N: false,
        mesTest26: false,
        disBtnTest26Y: false,
        disBtnTest26N: false,
        mesTest27: false,
        disBtnTest27Y: false,
        disBtnTest27N: false,
        mesTest28: false,
        disBtnTest28Y: false,
        disBtnTest28N: false,
        mesTest29: false,
        disBtnTest29Y: false,
        disBtnTest29N: false,
        mesTest30: false,
        disBtnTest30Y: false,
        disBtnTest30N: false,
        mesTest31: false,
        disBtnTest31Y: false,
        disBtnTest31N: false,
        mesTest32: false,
        disBtnTest32Y: false,
        disBtnTest32N: false,
        mesTest33: false,
        disBtnTest33Y: false,
        disBtnTest33N: false,
        mesTest34: false,
        disBtnTest34Y: false,
        disBtnTest34N: false,
        mesTest35: false,
        disBtnTest35Y: false,
        disBtnTest35N: false,
        mesTest36: false,
        disBtnTest36Y: false,
        disBtnTest36N: false,
        mesTest37: false,
        disBtnTest37Y: false,
        disBtnTest37N: false,
        mesTest38: false,
        disBtnTest38Y: false,
        disBtnTest38N: false,
        mesTest39: false,
        disBtnTest39Y: false,
        disBtnTest39N: false,
        mesTest40: false,
        disBtnTest40Y: false,
        disBtnTest40N: false,
        mesTest41: false,
        disBtnTest41Y: false,
        disBtnTest41N: false,
        mesTest42: false,
        disBtnTest42Y: false,
        disBtnTest42N: false,
        mesTest43: false,
        disBtnTest43Y: false,
        disBtnTest43N: false,
        mesTest44: false,
        disBtnTest44Y: false,
        disBtnTest44N: false,
        mesTest45: false,
        disBtnTest45Y: false,
        disBtnTest45N: false,
        mesTest46: false,
        disBtnTest46Y: false,
        disBtnTest46N: false,
        mesTest47: false,
        disBtnTest47Y: false,
        disBtnTest47N: false,
        mesTestRes: false,
        mesTestZone: false,
        mesTestReq: false,
    }),
    metaInfo: {
        title: 'Hyvinvointitesti',
        meta: [
            {
                vmid: 'description',
                property: 'description',
                content: 'Tee testi, joka osoittaa, millä terveyden alalla on ongelmia, ja saa asiantuntijoilta suosituksia terveyden vahvistamisesta ja ylläpitämisestä'
            },
            {vmid: 'og:title', property: 'og:title', content: 'Hyvinvointitesti'},
            {
                vmid: 'og:description',
                property: 'og:description',
                content: 'Tee testi, joka osoittaa, millä terveyden alalla on ongelmia, ja saa asiantuntijoilta suosituksia terveyden vahvistamisesta ja ylläpitämisestä'
            },
            {vmid: 'og:image', property: 'og:image', content: '/img/newlvl_logo.png'},
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'HealthTest', query: { inst: 'test', partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
        this.lr_number = this.$route.query.partner
        this.inst = this.$route.query.inst
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUserHealthTest',
        }),
        nameErrors() {
            const errors = []
            if (!this.$v.clientData.name.$dirty) return errors
            !this.$v.clientData.name.alpha && errors.push('Vain kirjaimet')
            !this.$v.clientData.name.minLength && errors.push('Vähintään 2 kirjainta')
            !this.$v.clientData.name.maxLength && errors.push('Enintään 30 kirjainta')
            !this.$v.clientData.name.required && errors.push('Pakollinen täytettäväksi')
            return errors
        },
        ageErrors() {
            const errors = []
            if (!this.$v.clientData.age.$dirty) return errors
            !this.$v.clientData.age.minLength && errors.push('Vähintään 1-numeroinen')
            !this.$v.clientData.age.maxLength && errors.push('Enintään 2 numeroa')
            !this.$v.clientData.age.required && errors.push('Pakollinen täytettäväksi')
            !this.$v.clientData.age.numeric && errors.push('Vain numeroita')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.clientData.phone.$dirty) return errors
            !this.$v.clientData.phone.minLength && errors.push('Vähintään 5 numeroa')
            !this.$v.clientData.phone.maxLength && errors.push('Enintään 20 numeroa')
            !this.$v.clientData.phone.required && errors.push('Pakollinen täytettäväksi')
            !this.$v.clientData.phone.numeric && errors.push('Vain numeroita')
            return errors
        },
        phoneWhatsappErrors() {
            const errors = []
            if (!this.$v.clientData.phone_whatsapp.$dirty) return errors
            !this.$v.clientData.phone_whatsapp.numeric && errors.push('Vain numeroita')
            !this.$v.clientData.phone_whatsapp.minLength && errors.push('Vähintään 5 numeroa')
            !this.$v.clientData.phone_whatsapp.maxLength && errors.push('Enintään 20 numeroa')
            !this.$v.clientData.phone_whatsapp.required && errors.push('Pakollinen täytettäväksi')
            return errors
        },
        phoneViberErrors() {
            const errors = []
            if (!this.$v.clientData.phone_viber.$dirty) return errors
            !this.$v.clientData.phone_viber.numeric && errors.push('Vain numeroita')
            !this.$v.clientData.phone_viber.minLength && errors.push('Vähintään 5 numeroa')
            !this.$v.clientData.phone_viber.maxLength && errors.push('Enintään 20 numeroa')
            !this.$v.clientData.phone_viber.required && errors.push('Pakollinen täytettäväksi')
            return errors
        },
        // telegramErrors() {
        //     const errors = []
        //     if (!this.$v.clientData.telegram.$dirty) return errors
        //     !this.$v.clientData.telegram.required && errors.push('Pakollinen täytettäväksi')
        //     return errors
        // }
    },
    methods: {
        ...mapActions({
            showUser: 'user/showUserTest',
            addNotification: 'application/addNotification',
            createProspect: 'user/addClient',
            updateProspect: 'prospect/updateProspect',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        // scrollToMe () {
        //     let scrollBottom = document.getElementById("scrollToMe")
        //     scrollBottom.scrollIntoView({ behavior: "smooth" })
        // },
        btnStartTest() {
            let v = this
            this.disBtnStartTest = true
            setTimeout(function () {
                v.blockLanding = false
            }, 500)
            setTimeout(function () {
                v.blockTest = true
            }, 1500)
        },
        btnOk1() {
            this.disBtnOk1 = true
            this.mesBiz1 = false
            this.showMesBiz2()
        },
        showMesBiz2() {
            let v = this
            setTimeout(function () {
                v.mesBiz2 = true
            }, 1000)
        },
        myName() {
            this.$v.clientData.name.$touch()
            if (!this.$v.clientData.name.$invalid) {
                this.disBtnName = true
                this.disInpName = true
                this.mesBiz2 = false
                this.showMesBiz3()
            }
        },
        showMesBiz3() {
            let v = this
            setTimeout(function () {
                v.mesBiz3 = true
            }, 1000)
        },
        btnM() {
            this.disBtnM = true
            this.disBtnG = true
            this.clientData.gender = 'м'
            this.mesBiz3 = false
            this.showMesBiz4()
        },
        btnG() {
            this.disBtnM = true
            this.disBtnG = true
            this.clientData.gender = 'ж'
            this.mesBiz3 = false
            this.showMesBiz4()
        },
        showMesBiz4() {
            let v = this
            setTimeout(function () {
                v.mesBiz4 = true
            }, 1000)
        },
        myAge() {
            this.$v.clientData.age.$touch()
            if (!this.$v.clientData.age.$invalid) {
                this.disInpAge = true
                this.disBtnAge = true
                this.mesBiz4 = false
                this.showMesBiz5()
            }
        },
        showMesBiz5() {
            let v = this
            setTimeout(function () {
                v.mesBiz5 = true
            }, 1000)
        },
        btnWhatsapp() {
            this.btnWhatsappTrue = true
            this.btnViberTrue = false
            // this.btnTelegramTrue = false
        },
        // btnTelegram() {
        //     this.btnWhatsappTrue = false
        //     this.btnViberTrue = false
        //     // this.btnTelegramTrue = true
        // },
        btnViber() {
            this.btnWhatsappTrue = false
            this.btnViberTrue = true
            // this.btnTelegramTrue = false
        },
        btnSendBiz() {
            this.$v.clientData.phone_whatsapp.$touch() || this.$v.clientData.phone_viber.$touch()
            if (!this.$v.clientData.phone_whatsapp.$invalid || !this.$v.clientData.phone_viber.$invalid) {
                this.disBtnBiz5 = true
                this.disInpWhatsapp = true
                this.disInpViber = true
                // this.disInpTelegram = true
                this.createProspect(this.clientData)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.addNotification({
                                show: true,
                                text: 'Onnistuneesti',
                                color: 'success'
                            })
                            this.prospectData.id = response.data.prospect_id
                            this.mesBiz5 = false
                            this.showMesTestStart()
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Vahvistusvirhe',
                })
            }
        },
        showMesTestStart() {
            let v = this
            setTimeout(function () {
                v.mesTestStart = true
            }, 1000)
        },
        btnTestStart() {
            this.disBtnTestStart = true
            this.mesTestStart = false
            this.showMesTest1()
        },
        showMesTest1() {
            let v = this
            setTimeout(function () {
                v.mesTest1 = true
            }, 1000)
        },
        btnTest1Y() {
            this.disBtnTest1Y = true
            this.disBtnTest1N = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.mesTest1 = false
            this.showMesTest2()
        },
        btnTest1N() {
            this.disBtnTest1Y = true
            this.disBtnTest1N = true
            this.mesTest1 = false
            this.showMesTest2()
        },
        showMesTest2() {
            let v = this
            setTimeout(function () {
                v.mesTest2 = true
            }, 1000)
        },
        btnTest2Y() {
            this.disBtnTest2Y = true
            this.disBtnTest2N = true
            this.testResults.imunSys += 1
            this.testResults.skinSys += 1
            this.mesTest2 = false
            this.showMesTest3()
        },
        btnTest2N() {
            this.disBtnTest2Y = true
            this.disBtnTest2N = true
            this.mesTest2 = false
            this.showMesTest3()
        },
        showMesTest3() {
            let v = this
            setTimeout(function () {
                v.mesTest3 = true
            }, 1000)
        },
        btnTest3Y() {
            this.disBtnTest3Y = true
            this.disBtnTest3N = true
            this.testResults.gktSys += 1
            this.testResults.breathSys += 1
            this.testResults.mochaSys += 1
            this.mesTest3 = false
            this.showMesTest4()
        },
        btnTest3N() {
            this.disBtnTest3Y = true
            this.disBtnTest3N = true
            this.mesTest3 = false
            this.showMesTest4()
        },
        showMesTest4() {
            let v = this
            setTimeout(function () {
                v.mesTest4 = true
            }, 1000)
        },
        btnTest4Y() {
            this.disBtnTest4Y = true
            this.disBtnTest4N = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.mesTest4 = false
            this.showMesTest5()
        },
        btnTest4N() {
            this.disBtnTest4Y = true
            this.disBtnTest4N = true
            this.mesTest4 = false
            this.showMesTest5()
        },
        showMesTest5() {
            let v = this
            setTimeout(function () {
                v.mesTest5 = true
            }, 1000)
        },
        btnTest5Y() {
            this.disBtnTest5Y = true
            this.disBtnTest5N = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.imunSys += 1
            this.testResults.breathSys += 1
            this.mesTest5 = false
            if (this.clientData.gender === 'ж') {
                this.showMesTest6()
            } else {
                this.showMesTest7()
            }

        },
        btnTest5N() {
            this.disBtnTest5Y = true
            this.disBtnTest5N = true
            this.mesTest5 = false
            if (this.clientData.gender === 'ж') {
                this.showMesTest6()
            } else {
                this.showMesTest7()
            }
        },
        showMesTest6() {
            let v = this
            setTimeout(function () {
                v.mesTest6 = true
            }, 1000)
        },
        btnTest6Y() {
            this.disBtnTest6Y = true
            this.disBtnTest6N = true
            this.testResults.gktSys += 1
            this.testResults.mochaSys += 1
            this.testResults.endoSys += 1
            this.mesTest6 = false
            this.showMesTest7()
        },
        btnTest6N() {
            this.disBtnTest6Y = true
            this.disBtnTest6N = true
            this.mesTest6 = false
            this.showMesTest7()
        },
        showMesTest7() {
            let v = this
            setTimeout(function () {
                v.mesTest7 = true
            }, 1000)
        },
        btnTest7Y() {
            this.disBtnTest7Y = true
            this.disBtnTest7N = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.mesTest7 = false
            this.showMesTest8()
        },
        btnTest7N() {
            this.disBtnTest7Y = true
            this.disBtnTest7N = true
            this.mesTest7 = false
            this.showMesTest8()
        },
        showMesTest8() {
            let v = this
            setTimeout(function () {
                v.mesTest8 = true
            }, 1000)
        },
        btnTest8Y() {
            this.disBtnTest8Y = true
            this.disBtnTest8N = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest8 = false
            this.showMesTest9()
        },
        btnTest8N() {
            this.disBtnTest8Y = true
            this.disBtnTest8N = true
            this.mesTest8 = false
            this.showMesTest9()
        },
        showMesTest9() {
            let v = this
            setTimeout(function () {
                v.mesTest9 = true
            }, 1000)
        },
        btnTest9Y() {
            this.disBtnTest9Y = true
            this.disBtnTest9N = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest9 = false
            this.showMesTest10()
        },
        btnTest9N() {
            this.disBtnTest9Y = true
            this.disBtnTest9N = true
            this.mesTest9 = false
            this.showMesTest10()
        },
        showMesTest10() {
            let v = this
            setTimeout(function () {
                v.mesTest10 = true
            }, 1000)
        },
        btnTest10Y() {
            this.disBtnTest10Y = true
            this.disBtnTest10N = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.testResults.breathSys += 1
            this.testResults.skinSys += 1
            this.mesTest10 = false
            this.showMesTest11()
        },
        btnTest10N() {
            this.disBtnTest10Y = true
            this.disBtnTest10N = true
            this.mesTest10 = false
            this.showMesTest11()
        },
        showMesTest11() {
            let v = this
            setTimeout(function () {
                v.mesTest11 = true
            }, 1000)
        },
        btnTest11Y() {
            this.disBtnTest11Y = true
            this.disBtnTest11N = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.mochaSys += 1
            this.testResults.skinSys += 1
            this.mesTest11 = false
            this.showMesTest12()
        },
        btnTest11N() {
            this.disBtnTest11Y = true
            this.disBtnTest11N = true
            this.mesTest11 = false
            this.showMesTest12()
        },
        showMesTest12() {
            let v = this
            setTimeout(function () {
                v.mesTest12 = true
            }, 1000)
        },
        btnTest12Y() {
            this.disBtnTest12Y = true
            this.disBtnTest12N = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.breathSys += 1
            this.testResults.skinSys += 1
            this.mesTest12 = false
            this.showMesTest13()
        },
        btnTest12N() {
            this.disBtnTest12Y = true
            this.disBtnTest12N = true
            this.mesTest12 = false
            this.showMesTest13()
        },
        showMesTest13() {
            let v = this
            setTimeout(function () {
                v.mesTest13 = true
            }, 1000)
        },
        btnTest13Y() {
            this.disBtnTest13Y = true
            this.disBtnTest13N = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest13 = false
            this.showMesTest14()
        },
        btnTest13N() {
            this.disBtnTest13Y = true
            this.disBtnTest13N = true
            this.mesTest13 = false
            this.showMesTest14()
        },
        showMesTest14() {
            let v = this
            setTimeout(function () {
                v.mesTest14 = true
            }, 1000)
        },
        btnTest14Y() {
            this.disBtnTest14Y = true
            this.disBtnTest14N = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.mesTest14 = false
            this.showMesTest15()
        },
        btnTest14N() {
            this.disBtnTest14Y = true
            this.disBtnTest14N = true
            this.mesTest14 = false
            this.showMesTest15()
        },
        showMesTest15() {
            let v = this
            setTimeout(function () {
                v.mesTest15 = true
            }, 1000)
        },
        btnTest15Y() {
            this.disBtnTest15Y = true
            this.disBtnTest15N = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.testResults.skinSys += 1
            this.mesTest15 = false
            this.showMesTest16()
        },
        btnTest15N() {
            this.disBtnTest15Y = true
            this.disBtnTest15N = true
            this.mesTest15 = false
            this.showMesTest16()
        },
        showMesTest16() {
            let v = this
            setTimeout(function () {
                v.mesTest16 = true
            }, 1000)
        },
        btnTest16Y() {
            this.disBtnTest16Y = true
            this.disBtnTest16N = true
            this.testResults.gktSys += 1
            this.testResults.mochaSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.testResults.skinSys += 1
            this.mesTest16 = false
            this.showMesTest17()
        },
        btnTest16N() {
            this.disBtnTest16Y = true
            this.disBtnTest16N = true
            this.mesTest16 = false
            this.showMesTest17()
        },
        showMesTest17() {
            let v = this
            setTimeout(function () {
                v.mesTest17 = true
            }, 1000)
        },
        btnTest17Y() {
            this.disBtnTest17Y = true
            this.disBtnTest17N = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest17 = false
            this.showMesTest18()
        },
        btnTest17N() {
            this.disBtnTest17Y = true
            this.disBtnTest17N = true
            this.mesTest17 = false
            this.showMesTest18()
        },
        showMesTest18() {
            let v = this
            setTimeout(function () {
                v.mesTest18 = true
            }, 1000)
        },
        btnTest18Y() {
            this.disBtnTest18Y = true
            this.disBtnTest18N = true
            this.testResults.gktSys += 1
            this.testResults.breathSys += 1
            this.mesTest18 = false
            this.showMesTest19()
        },
        btnTest18N() {
            this.disBtnTest18Y = true
            this.disBtnTest18N = true
            this.mesTest18 = false
            this.showMesTest19()
        },
        showMesTest19() {
            let v = this
            setTimeout(function () {
                v.mesTest19 = true
            }, 1000)
        },
        btnTest19Y() {
            this.disBtnTest19Y = true
            this.disBtnTest19N = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest19 = false
            this.showMesTest20()
        },
        btnTest19N() {
            this.disBtnTest19Y = true
            this.disBtnTest19N = true
            this.mesTest19 = false
            this.showMesTest20()
        },
        showMesTest20() {
            let v = this
            setTimeout(function () {
                v.mesTest20 = true
            }, 1000)
        },
        btnTest20Y() {
            this.disBtnTest20Y = true
            this.disBtnTest20N = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest20 = false
            if (this.clientData.gender === 'ж') {
                this.showMesTest21()
            } else {
                this.showMesTest22()
            }
        },
        btnTest20N() {
            this.disBtnTest20Y = true
            this.disBtnTest20N = true
            this.mesTest20 = false
            if (this.clientData.gender === 'ж') {
                this.showMesTest21()
            } else {
                this.showMesTest22()
            }
        },
        showMesTest21() {
            let v = this
            setTimeout(function () {
                v.mesTest21 = true
            }, 1000)
        },
        btnTest21Y() {
            this.disBtnTest21Y = true
            this.disBtnTest21N = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest21 = false
            this.showMesTest22()
        },
        btnTest21N() {
            this.disBtnTest21Y = true
            this.disBtnTest21N = true
            this.mesTest21 = false
            this.showMesTest22()
        },
        showMesTest22() {
            let v = this
            setTimeout(function () {
                v.mesTest22 = true
            }, 1000)
        },
        btnTest22Y() {
            this.disBtnTest22Y = true
            this.disBtnTest22N = true
            this.testResults.mochaSys += 1
            this.mesTest22 = false
            this.showMesTest23()
        },
        btnTest22N() {
            this.disBtnTest22Y = true
            this.disBtnTest22N = true
            this.mesTest22 = false
            this.showMesTest23()
        },
        showMesTest23() {
            let v = this
            setTimeout(function () {
                v.mesTest23 = true
            }, 1000)
        },
        btnTest23Y() {
            this.disBtnTest23Y = true
            this.disBtnTest23N = true
            this.testResults.skinSys += 1
            this.mesTest23 = false
            this.showMesTest24()
        },
        btnTest23N() {
            this.disBtnTest23Y = true
            this.disBtnTest23N = true
            this.mesTest23 = false
            this.showMesTest24()
        },
        showMesTest24() {
            let v = this
            setTimeout(function () {
                v.mesTest24 = true
            }, 1000)
        },
        btnTest24Y() {
            this.disBtnTest24Y = true
            this.disBtnTest24N = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest24 = false
            this.showMesTest25()
        },
        btnTest24N() {
            this.disBtnTest24Y = true
            this.disBtnTest24N = true
            this.mesTest24 = false
            this.showMesTest25()
        },
        showMesTest25() {
            let v = this
            setTimeout(function () {
                v.mesTest25 = true
            }, 1000)
        },
        btnTest25Y() {
            this.disBtnTest25Y = true
            this.disBtnTest25N = true
            this.testResults.heartSys += 1
            this.testResults.imunSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest25 = false
            this.showMesTest26()
        },
        btnTest25N() {
            this.disBtnTest25Y = true
            this.disBtnTest25N = true
            this.mesTest25 = false
            this.showMesTest26()
        },
        showMesTest26() {
            let v = this
            setTimeout(function () {
                v.mesTest26 = true
            }, 1000)
        },
        btnTest26Y() {
            this.disBtnTest26Y = true
            this.disBtnTest26N = true
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest26 = false
            this.showMesTest27()
        },
        btnTest26N() {
            this.disBtnTest26Y = true
            this.disBtnTest26N = true
            this.mesTest26 = false
            this.showMesTest27()
        },
        showMesTest27() {
            let v = this
            setTimeout(function () {
                v.mesTest27 = true
            }, 1000)
        },
        btnTest27Y() {
            this.disBtnTest27Y = true
            this.disBtnTest27N = true
            this.testResults.heartSys += 1
            this.testResults.breathSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest27 = false
            this.showMesTest28()
        },
        btnTest27N() {
            this.disBtnTest27Y = true
            this.disBtnTest27N = true
            this.mesTest27 = false
            this.showMesTest28()
        },
        showMesTest28() {
            let v = this
            setTimeout(function () {
                v.mesTest28 = true
            }, 1000)
        },
        btnTest28Y() {
            this.disBtnTest28Y = true
            this.disBtnTest28N = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.skinSys += 1
            this.mesTest28 = false
            this.showMesTest29()
        },
        btnTest28N() {
            this.disBtnTest28Y = true
            this.disBtnTest28N = true
            this.mesTest28 = false
            this.showMesTest29()
        },
        showMesTest29() {
            let v = this
            setTimeout(function () {
                v.mesTest29 = true
            }, 1000)
        },
        btnTest29Y() {
            this.disBtnTest29Y = true
            this.disBtnTest29N = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.mesTest29 = false
            this.showMesTest30()
        },
        btnTest29N() {
            this.disBtnTest29Y = true
            this.disBtnTest29N = true
            this.mesTest29 = false
            this.showMesTest30()
        },
        showMesTest30() {
            let v = this
            setTimeout(function () {
                v.mesTest30 = true
            }, 1000)
        },
        btnTest30Y() {
            this.disBtnTest30Y = true
            this.disBtnTest30N = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.skinSys += 1
            this.mesTest30 = false
            this.showMesTest31()
        },
        btnTest30N() {
            this.disBtnTest30Y = true
            this.disBtnTest30N = true
            this.mesTest30 = false
            this.showMesTest31()
        },
        showMesTest31() {
            let v = this
            setTimeout(function () {
                v.mesTest31 = true
            }, 1000)
        },
        btnTest31Y() {
            this.disBtnTest31Y = true
            this.disBtnTest31N = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest31 = false
            this.showMesTest32()
        },
        btnTest31N() {
            this.disBtnTest31Y = true
            this.disBtnTest31N = true
            this.mesTest31 = false
            this.showMesTest32()
        },
        showMesTest32() {
            let v = this
            setTimeout(function () {
                v.mesTest32 = true
            }, 1000)
        },
        btnTest32Y() {
            this.disBtnTest32Y = true
            this.disBtnTest32N = true
            this.testResults.gktSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest32 = false
            this.showMesTest33()
        },
        btnTest32N() {
            this.disBtnTest32Y = true
            this.disBtnTest32N = true
            this.mesTest32 = false
            this.showMesTest33()
        },
        showMesTest33() {
            let v = this
            setTimeout(function () {
                v.mesTest33 = true
            }, 1000)
        },
        btnTest33Y() {
            this.disBtnTest33Y = true
            this.disBtnTest33N = true
            this.testResults.gktSys += 1
            this.testResults.mochaSys += 1
            this.mesTest33 = false
            this.showMesTest34()
        },
        btnTest33N() {
            this.disBtnTest33Y = true
            this.disBtnTest33N = true
            this.mesTest33 = false
            this.showMesTest34()
        },
        showMesTest34() {
            let v = this
            setTimeout(function () {
                v.mesTest34 = true
            }, 1000)
        },
        btnTest34Y() {
            this.disBtnTest34Y = true
            this.disBtnTest34N = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.mesTest34 = false
            this.showMesTest35()
        },
        btnTest34N() {
            this.disBtnTest34Y = true
            this.disBtnTest34N = true
            this.mesTest34 = false
            this.showMesTest35()
        },
        showMesTest35() {
            let v = this
            setTimeout(function () {
                v.mesTest35 = true
            }, 1000)
        },
        btnTest35Y() {
            this.disBtnTest35Y = true
            this.disBtnTest35N = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.mesTest35 = false
            this.showMesTest36()
        },
        btnTest35N() {
            this.disBtnTest35Y = true
            this.disBtnTest35N = true
            this.mesTest35 = false
            this.showMesTest36()
        },
        showMesTest36() {
            let v = this
            setTimeout(function () {
                v.mesTest36 = true
            }, 1000)
        },
        btnTest36Y() {
            this.disBtnTest36Y = true
            this.disBtnTest36N = true
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest36 = false
            this.showMesTest37()
        },
        btnTest36N() {
            this.disBtnTest36Y = true
            this.disBtnTest36N = true
            this.mesTest36 = false
            this.showMesTest37()
        },
        showMesTest37() {
            let v = this
            setTimeout(function () {
                v.mesTest37 = true
            }, 1000)
        },
        btnTest37Y() {
            this.disBtnTest37Y = true
            this.disBtnTest37N = true
            this.testResults.imunSys += 1
            this.testResults.breathSys += 1
            this.testResults.skinSys += 1
            this.mesTest37 = false
            this.showMesTest38()
        },
        btnTest37N() {
            this.disBtnTest37Y = true
            this.disBtnTest37N = true
            this.mesTest37 = false
            this.showMesTest38()
        },
        showMesTest38() {
            let v = this
            setTimeout(function () {
                v.mesTest38 = true
            }, 1000)
        },
        btnTest38Y() {
            this.disBtnTest38Y = true
            this.disBtnTest38N = true
            this.testResults.heartSys += 1
            this.testResults.endoSys += 1
            this.mesTest38 = false
            this.showMesTest39()
        },
        btnTest38N() {
            this.disBtnTest38Y = true
            this.disBtnTest38N = true
            this.mesTest38 = false
            this.showMesTest39()
        },
        showMesTest39() {
            let v = this
            setTimeout(function () {
                v.mesTest39 = true
            }, 1000)
        },
        btnTest39Y() {
            this.disBtnTest39Y = true
            this.disBtnTest39N = true
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest39 = false
            this.showMesTest40()
        },
        btnTest39N() {
            this.disBtnTest39Y = true
            this.disBtnTest39N = true
            this.mesTest39 = false
            this.showMesTest40()
        },
        showMesTest40() {
            let v = this
            setTimeout(function () {
                v.mesTest40 = true
            }, 1000)
        },
        btnTest40Y() {
            this.disBtnTest40Y = true
            this.disBtnTest40N = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.mesTest40 = false
            this.showMesTest41()
        },
        btnTest40N() {
            this.disBtnTest40Y = true
            this.disBtnTest40N = true
            this.mesTest40 = false
            this.showMesTest41()
        },
        showMesTest41() {
            let v = this
            setTimeout(function () {
                v.mesTest41 = true
            }, 1000)
        },
        btnTest41Y() {
            this.disBtnTest41Y = true
            this.disBtnTest41N = true
            this.testResults.gktSys += 1
            this.testResults.imunSys += 1
            this.testResults.mochaSys += 1
            this.mesTest41 = false
            this.showMesTest42()
        },
        btnTest41N() {
            this.disBtnTest41Y = true
            this.disBtnTest41N = true
            this.mesTest41 = false
            this.showMesTest42()
        },
        showMesTest42() {
            let v = this
            setTimeout(function () {
                v.mesTest42 = true
            }, 1000)
        },
        btnTest42Y() {
            this.disBtnTest42Y = true
            this.disBtnTest42N = true
            this.testResults.gktSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest42 = false
            this.showMesTest43()
        },
        btnTest42N() {
            this.disBtnTest42Y = true
            this.disBtnTest42N = true
            this.mesTest42 = false
            this.showMesTest43()
        },
        showMesTest43() {
            let v = this
            setTimeout(function () {
                v.mesTest43 = true
            }, 1000)
        },
        btnTest43Y() {
            this.disBtnTest43Y = true
            this.disBtnTest43N = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.mesTest43 = false
            this.showMesTest44()
        },
        btnTest43N() {
            this.disBtnTest43Y = true
            this.disBtnTest43N = true
            this.mesTest43 = false
            this.showMesTest44()
        },
        showMesTest44() {
            let v = this
            setTimeout(function () {
                v.mesTest44 = true
            }, 1000)
        },
        btnTest44Y() {
            this.disBtnTest44Y = true
            this.disBtnTest44N = true
            this.testResults.gktSys += 1
            this.testResults.nervSys += 1
            this.testResults.endoSys += 1
            this.mesTest44 = false
            this.showMesTest45()
        },
        btnTest44N() {
            this.disBtnTest44Y = true
            this.disBtnTest44N = true
            this.mesTest44 = false
            this.showMesTest45()
        },
        showMesTest45() {
            let v = this
            setTimeout(function () {
                v.mesTest45 = true
            }, 1000)
        },
        btnTest45Y() {
            this.disBtnTest45Y = true
            this.disBtnTest45N = true
            this.testResults.gktSys += 1
            this.testResults.heartSys += 1
            this.testResults.nervSys += 1
            this.testResults.imunSys += 1
            this.testResults.endoSys += 1
            this.testResults.opdvigSys += 1
            this.mesTest45 = false
            this.showMesTest46()
        },
        btnTest45N() {
            this.disBtnTest45Y = true
            this.disBtnTest45N = true
            this.mesTest45 = false
            this.showMesTest46()
        },
        showMesTest46() {
            let v = this
            setTimeout(function () {
                v.mesTest46 = true
            }, 1000)
        },
        btnTest46Y() {
            this.disBtnTest46Y = true
            this.disBtnTest46N = true
            this.testResults.gktSys += 1
            this.testResults.breathSys += 1
            this.mesTest46 = false
            this.showMesTest47()
        },
        btnTest46N() {
            this.disBtnTest46Y = true
            this.disBtnTest46N = true
            this.mesTest46 = false
            this.showMesTest47()
        },
        showMesTest47() {
            let v = this
            setTimeout(function () {
                v.mesTest47 = true
            }, 1000)
        },
        btnTest47Y() {
            this.disBtnTest47Y = true
            this.disBtnTest47N = true
            this.testResults.skinSys += 1
            this.prospectData.test_results = this.testResults.gktSys + ',' + this.testResults.heartSys + ',' + this.testResults.nervSys + ',' + this.testResults.imunSys + ',' + this.testResults.breathSys + ',' + this.testResults.mochaSys + ',' + this.testResults.endoSys + ',' + this.testResults.opdvigSys + ',' + this.testResults.skinSys
            this.mesTest47 = false
            this.showMesTestEnd()
            authService.updateProspect(this.prospectData.id, this.prospectData)
                .then((response) => {
                    this.updateProspect(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loading = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 422:
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        case 500:
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        btnTest47N() {
            this.disBtnTest47Y = true
            this.disBtnTest47N = true
            this.prospectData.test_results = this.testResults.gktSys + ',' + this.testResults.heartSys + ',' + this.testResults.nervSys + ',' + this.testResults.imunSys + ',' + this.testResults.breathSys + ',' + this.testResults.mochaSys + ',' + this.testResults.endoSys + ',' + this.testResults.opdvigSys + ',' + this.testResults.skinSys
            this.mesTest47 = false
            this.showMesTestEnd()
            authService.updateProspect(this.prospectData.id, this.prospectData)
                .then((response) => {
                    this.updateProspect(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loading = false
                        this.addNotification({
                            show: true,
                            text: 'Testitulokset on päivitetty onnistuneesti',
                            color: 'success'
                        })
                    }
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 422:
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        case 500:
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        showMesTestEnd() {
            let v = this
            setTimeout(function () {
                v.mesTestRes = true
            }, 1000)
            setTimeout(function () {
                v.mesTestZone = true
            }, 6000)
            setTimeout(function () {
                v.mesTestReq = true
            }, 19000)
        },
    },
    mounted() {
        this.showUser(this.lr_number)
        this.loading = false

        // this.showTyping()

        this.clientData.partner = this.$route.query.partner
        this.clientData.inst = this.$route.query.inst
    },
    // updated() {
    //     this.scrollToMe()
    // },
    validations: {
        clientData: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
                alpha
            },
            age: {
                required,
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(2)
            },
            income_want: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(191)
            },
            phone: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(20)
            },
            phone_whatsapp: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(20)
            },
            phone_viber: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(20)
            },
            // telegram: {
            //     required
            // }
        }
    }
}
</script>

<style lang="sass">
#htest-main
    background-color: #FCFAF8
    background-position: top right
    background-repeat: no-repeat
    background-size: contain
    position: relative
    background-image: linear-gradient(180deg, #002416 0%, #03311F 100%)

.htest-main
    background-color: #FCFAF8 !important

#htest-start
    background-color: #FCFAF8
    padding-bottom: 60px
    padding-top: 40px

#htest-feedback
    background-color: #f2f2f2

.htest-h2-feedback
    text-align: center
    font-size: 32px
    margin-top: 20px
    margin-bottom: 40px
    padding: 0 10px
    font-weight: 400

.htest-feedback
    background-color: #fff
    padding: 30px 40px
    border-radius: 5px
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)

.htest-feedback p
    color: #333333
    font-style: italic

.htest-fb-who
    padding-top: 10px

.htest-fb-avatar
    top: -10px
    margin-bottom: 0
    display: inline-block

.htest-fb-text
    display: inline-block
    margin-left: 20px

.htest-fb-text h3
    color: #333333

.htest-fb-text p
    color: #666666

.htest-img
    box-shadow: 10px 10px 0 0 #03311F

.htest-divider
    text-align: center
    margin: 40px 0

.htest-divider .v-icon
    color: #03311F

.media-img
    display: none

.htest-food
    background-color: #03311F
    padding: 40px
    position: absolute
    top: 15%
    right: -40px
    z-index: 10

.htest-h1-food
    font-size: 40px
    line-height: 48px
    color: white
    margin-bottom: 40px
    font-weight: 400

.htest-subtitle-food
    font-size: 18px
    font-weight: 300
    color: white
    margin-bottom: 40px

.htest-btn-food-div
    margin-bottom: 5px

.htest-food-btn
    width: 60px
    display: inline-block

.htest-food-btn-txt
    display: inline-block
    margin-bottom: 0 !important
    color: #002617

.htest-btn-fb-div
    margin-top: 40px
    margin-bottom: 60px

.htest-fb-btn .v-btn
    margin-right: 0 !important

.htest-fb-btn-txt
    color: #fff
    margin-bottom: 0 !important

.htest-list-item
    margin-right: 10px

.htest-list-item:last-child
    margin-right: 0

.htest-video-div
    margin-bottom: 40px

.htest-video-btn
    width: 60px
    display: inline-block

.htest-video-btn-txt
    display: inline-block
    margin-bottom: 0 !important
    color: #002617

.htest-line-title
    position: absolute
    top: 50%
    left: 50%
    width: calc(100% + 20px)
    height: calc(100% + 20px)
    -webkit-transform: translate(-50%,-50%)
    -ms-transform: translate(-50%,-50%)
    transform: translate(-50%,-50%)
    overflow: visible

.htest-h2-main-view
    font-size: 24px
    margin-bottom: 20px

.htest-h2-steps
    font-size: 24px
    margin-bottom: 23px

.htest-line-title path
    stroke: #03311F
    stroke-width: 9
    fill: none
    opacity: 1
    stroke-dasharray: 1500 1500
    -webkit-transition: .3s
    -o-transition: .3s
    transition: .3s

.p-htest
    font-size: 16px

.htest-divider
    text-align: center
    margin: 40px 0

.htest-divider .v-icon
    color: #03311F

.htest-border-left
    border-left: 3px solid #03311F
    padding-left: 20px

.htest-ul-steps, .htest-ul-award
    font-size: 16px
    list-style-type: none
    padding: 0 !important
    margin-bottom: 20px

.htest-ul-steps li, .htest-ul-award li
    line-height: 2rem
    margin-bottom: 10px

.htest-steps li .v-icon, .htest-ul-award .v-icon
    padding-right: 7px
    color: #03311F

.htest-blockquote
    font-size: 16px
    font-style: italic

.rev-p-htest
    font-weight: bold
    text-transform: uppercase
    line-height: 18px !important
    margin-bottom: 30px !important
    color: #333
    font-size: 22px
    display: inline-block
    position: relative
    margin-top: 40%

.htest-logo
    max-width: 250px
    margin-top: 15%
    margin-bottom: 40px

.htest-iphone
    position: absolute
    right: 0
    bottom: 0
    max-width: 550px

.htest-food-img


.rev-p-htest span
    position: relative
    top: 15px

.htest-h1
    color: #fff
    font-size: 48px
    line-height: 56px
    font-weight: 400
    margin-bottom: 40px
    position: relative

.htest-img-in
    max-width: 250px

.htest-subtitle-main
    position: relative
    margin-bottom: 40px
    color: white

.htest-subtitle-main p
    font-size: 16px
    font-weight: 300

.htest-ul-main
    list-style-type: none
    margin-top: 30px
    padding-left: 10px !important
    margin-bottom: 20px

.htest-ul-main li
    font-size: 22px
    line-height: 34px
    color: #333333

.htest-icon-main
    color: #03311F !important
    padding-right: 5px

.htest-h2
    font-size: 24px
    text-transform: uppercase
    text-align: center
    margin-top: 10px
    margin-bottom: 10px

.htest-h2-center
    font-size: 36px
    margin-top: 10px
    margin-bottom: 10px
    color: #03311F
    text-transform: uppercase
    text-align: center

.htest-exp-panel .v-icon
    color: #03311F !important

.htest-exp-title
    font-size: 18px
    background-color: #dfffda

.htest-step-p-exp
    font-size: 16px

.htest-footer
    background-color: #03311F !important
    color: white !important

.htest-consultant-p
    font-size: 24px

.chat-list p
    font-size: 16px

.chat-list
    width: 100%
    height: 100%
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 0 11px 20px

.chat-item
    display: flex
    margin-bottom: 15px

.chat-item.item-user
    width: 100%
    align-self: flex-end
    flex-direction: row-reverse
    margin-top: 40px
    margin-bottom: 40px

.chat-item p
    margin-bottom: 0

.chat-item ul
    list-style: none
    padding: 0

.chat-item ul li i
    top: -2px

.p-quest-num
    color: #999999
    font-size: 13px !important

.chat-avatar
    width: 32px
    height: 32px
    margin-top: 20px
    margin-right: 10px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-color: #999999
    flex-shrink: 0
    border-radius: 50%

.chat-avatar img
    width: 100%
    border-radius: 50%

.chat-block
    min-width: 60%
    max-width: 100%

.chat-date
    opacity: 0.5
    color: #252525
    font-size: 14px
    font-weight: 400
    margin-bottom: 5px

.chat-messages
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start

.chat-message
    display: inline-block
    //background-color: rgb(248, 248, 248)
    padding: 15px 20px
    color: rgb(37, 37, 37)
    font-size: 14px
    font-weight: 400
    line-height: 1.5
    margin-bottom: 5px
    min-width: 250px

.chat-message:last-of-type
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px

.chat-message:first-of-type
    border-top-left-radius: 10px
    border-top-right-radius: 10px

.chat-input
    margin-bottom: 10px
    margin-left: 20px

.chat-controls
    display: flex
    margin-top: 10px
    align-items: center
    flex-wrap: wrap
    margin-left: 20px

.chat-controls.quest
    padding: 0 20px

.chat-controls-end
    display: flex
    margin-top: 10px
    align-items: center
    flex-wrap: wrap
    justify-content: flex-start

.chat-btn
    margin-bottom: 10px !important
    background-color: #4caf50 !important

.chat-btn.btn-skip
    background-color: gray !important

.chat-btn.btn-send
    margin-left: auto

.item-user .chat-avatar-user
    background-image: url("/img/leedbot/user_avatar.png")
    margin-left: 10px
    margin-right: 0
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-color: #999999
    flex-shrink: 0
    border-radius: 50%
    width: 32px
    height: 32px
    margin-top: 20px

.item-user .chat-date
    text-align: right

.item-user .chat-messages
    align-items: flex-end

.chat-typing
    font-size: 12px
    color: #999999
    display: flex
    align-items: baseline
    margin-left: 45px
    opacity: 0
    transform: translateY(5px)

.chat-typing.show
    opacity: 1
    transform: translateY(0)
    transition: 0.5s

.chat-typing-animate
    width: 27px
    overflow: hidden
    margin-left: 5px

.chat-typing-box
    display: flex

.span-res-sys
    font-weight: 500

.tbl-sys  p
    font-size: 12px !important
    color: #666

.tbl-very-good
    text-align: center
    background-color: #bcfea4
    position: relative

.tbl-good
    text-align: center
    background-color: #b7e0fe
    position: relative

.tbl-risk
    text-align: center
    background-color: #feffb3
    position: relative

.tbl-bad
    text-align: center
    background-color: #ffc5c4
    position: relative

.table-label
    color: #666
    font-size: 13px
    position: absolute
    left: 5px
    top: 0

.table-result
    position: absolute
    left: calc(47.5% - 18px)
    font-size: 22px
    background: #fff
    border-radius: 50%
    width: 36px
    height: 36px
    text-align: center
    border: 2px solid #c00
    bottom: 5px
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.3s
    font-weight: 600
    color: #333
    z-index: 300

.sys-icon
    display: none
    text-align: center
    padding-top: 7px

.tbl-sys .v-tooltip
    z-index: 999

.sys-icon img
    width: 32px

.sys-text
    margin: 0 !important

@keyframes typing
    0%
        transform: translateX(-30%)
    100%
        transform: translateX(0px)

@-webkit-keyframes typing
    0%
        transform: translateX(-30%)
    100%
        transform: translateX(0px)

.chat-typing.show .chat-typing-box
    -webkit-animation: typing 1s infinite
    animation: typing 1s infinite

.chat-typing-box img
    width: 12px
    height: 12px

.fade-enter-active, .fade-leave-active
    transition: opacity 1s

.fade-enter, .fade-leave-to
    opacity: 0

@media(max-width: 1320px)
    .htest-food
        top: 5%

    .htest-h1-food
        font-size: 36px
        line-height: 44px

@media(max-width: 1200px)
    #htest-skin
        padding-bottom: 0

    .converter-main
        margin-left: 40px

    .htest-iphone
        width: 500px

    .htest-logo
        margin-top: 10%

    .htest-h1
        font-size: 40px

    .htest-h1-food
        margin-bottom: 20px

@media(max-width: 980px)
    .htest-iphone
        width: 400px

    .htest-food
        padding: 30px
        top: 10%

    .htest-h1-food
        font-size: 32px
        line-height: 42px

@media(max-width: 959px)
    .media-img
        display: inline-block

    .right-img
        display: none

    .rev-p-htest
        margin-top: 10%

    .htest-h1
        line-height: 44px

    .htest-subtitle-main
        max-width: 450px

    .htest-logo
        margin-top: 5%
        max-width: 200px

    .htest-iphone
        max-width: 300px

    .htest-food
        top: 0
        right: 0
        position: relative

    .htest-food-img
        max-width: 500px
        margin: 0 auto
        top: -25px

@media(max-width: 760px)
    .lifetakt-htest
        margin-bottom: 20px

    .htest-subtitle-main
        max-width: 300px

    .converter-main
        margin-left: 20px

@media(max-width: 600px)
    .main-col
        padding-left: 0
        padding-right: 0

    .chat-list
        padding-left: 0
        padding-right: 0

    .htest-iphone
        max-width: 250px

    .htest-h1
        font-size: 36px

    #htest-start .container, #htest-feedback .container
        padding: 0

    .htest-cont-two
        padding: 0

@media(max-width: 550px)
    .htest-iphone
        max-width: 200px

@media(max-width: 500px)
    .converter-main
        margin-left: 0

    .lifetakt-htest
        margin-left: 0

@media(max-width: 480px)
    //.sys-icon
    //    display: block
    //
    //.sys-text
    //    display: none

    .tbl-sys
        padding: 0 2px !important
        font-size: 11px !important

    .sys-text
        line-height: 1.5rem !important

    .v-dialog
        margin-right: 5px
        margin-left: 5px

    .dialog-card-title
        padding-top: 10px !important

    .dialog-card-results
        padding: 10px 5px !important

    .dialog-th
        padding: 0 5px !important

    .rev-p-htest
        max-width: 200px
        line-height: 18px !important
        margin-bottom: 30px !important
        font-size: 14px

    .htest-logo
        max-width: 150px
        margin-bottom: 30px

    .htest-h1
        font-size: 32px
        line-height: 38px
        margin-bottom: 30px

    .htest-subtitle-main
        margin-bottom: 80px

    .htest-iphone
        width: 180px

    .htest-video-div
        margin-bottom: 80px

    .htest-feedback
        padding: 20px

    .htest-food
        padding: 20px

    .htest-h2-feedback
        font-size: 28px

</style>